<template>
    <v-snackbar
        color="orange darken-1"
        :timeout="30000"
        v-model="snackbar"
        multi-line
    >
        {{ text }}
        <v-btn text href="https://www.google.com/chrome" target="_blank">
            google.com/chrome
        </v-btn>
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
                {{ $t("close") }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    mounted() {
        this.snackbar = this.isBrowserNotValid();
    },
    data() {
        return {
            snackbar: false,
            text: this.$t("notValidBrowser")
        };
    },
    methods: {
        isBrowserNotValid() {
            try {
                return (
                    this.$browserDetect.isFirefox ||
                    this.$browserDetect.isIE ||
                    this.$browserDetect.isSafari
                );
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>

<style></style>
