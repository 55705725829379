<template>
    <v-app>
        <v-main>
            <router-view />
            <!-- <dashboard-core-footer /> -->
        </v-main>
        <IsValidBrowser />
    </v-app>
</template>

<script>
import IsValidBrowser from "../components/IsValidBrowser.vue";

export default {
    components: {
        IsValidBrowser
    },
    name: "Auth",
    data: () => ({
        expandOnHover: false
    })
};
</script>
<style scoped>
/* .main {
  background-image: url("/src/assets/dental.jpg");
  background-position: center center;
  background-size: contain;
} */
</style>
